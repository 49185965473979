






















































































.responsibility {
    .content {
        padding: 0.77rem 0;

        @media (max-width: 767px) {
            padding: 0.3rem 0;
        }

        .title {
            color: #333333;
            font-size: 0.3rem;
            font-weight: bold;
            margin-bottom: 0.5rem;

            @media (max-width: 767px) {
                margin-bottom: 0.3rem;
				font-size: 0.4rem;
            }
        }

        .text {
            color: #333333;
            font-size: 0.24rem;
            line-height: 2;
            margin-bottom: 0.2rem;
            text-align: justify;
			@media (max-width: 767px) {
			     font-size: 0.38rem;
			}
        }
    }

    .content:nth-child(odd) {
        background: #eeeeee;
    }
}
